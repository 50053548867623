<template>
  <Teleport to="body">
    <div
      class="modalBg modalHeight w-full flex items-center justify-center mobOnly:px-12"
      @click.self="background"
    >
      <div class="bg-lightest w-full desk:w-556">
        <div v-if="message" class="p-32 min-h-160 type-lg">
          {{ message }}
        </div>
        <slot />
        <div class="flex">
          <button
            v-if="showAbort"
            class="btn btn--secondary"
            :class="{
              'basis-1/2 shrink-0': showConfirm,
              'w-full': !showConfirm,
            }"
            @click="abort"
          >
            {{ abortText ? abortText : $t('modal.generic.abort') }}
          </button>
          <button
            v-if="showConfirm"
            class="btn"
            :class="{
              'basis-1/2 shrink-0': showAbort,
              'w-full': !showAbort
            }"
            @click="confirm"
          >
            {{ confirmText ? confirmText : $t('modal.generic.ok') }}
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
const { $t } = useNuxtApp();
const props = withDefaults(defineProps<{
  message?: string,
  showAbort?: boolean,
  showConfirm?: boolean,
  abortText?: string,
  confirmText?: string,
}>(), {
  message: '',
  showAbort: () => false,
  showConfirm: () => true,
  abortText: '',
  confirmText: '',
});

const emit = defineEmits<{
  (e: 'abort'): void;
  (e: 'confirm'): void;
  (e: 'close'): void;
  (e: 'clickOutside'): void;
}>();

const background = () => {
  if (!props.showAbort) {
    confirm();
  }
  emit('clickOutside');

};

const confirm = () => {
  emit('confirm');
  emit('close');
};

const abort = () => {
  emit('abort');
  emit('close');
};

</script>

<style scoped lang="postcss">
</style>
